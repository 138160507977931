import { graphql } from "gatsby";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { CallToAction } from "../components/CallToAction";
import { GuaranteeCard, PestCard } from "../components/Card";
import { TestimonialsCarousel } from "../components/Carousel";
import { GuaranteeFlexbox, TermitesFlexbox } from "../components/Flexbox";
import { CommonPestsGrid } from "../components/Grid";
import { ServiceHero } from "../components/Hero";
import { ProcessItem, Stack } from "../components/Stack";
import { GuaranteeWrap } from "../components/Wrap";


interface Props {
  data: any
};


const ServiceTemplate= ({
  data
}: Props ) => {
  const pests = data?.allPrismicPest?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const service = data?.prismicService?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ service.title.text }
        description={ service.description.text }
      />

      <SectionWrapper
        background="background.linen"
      >
        <SectionContainer>
          <ServiceHero
            heading={ service.hero_heading.text }
            description={ service.hero_description.html }
            image={ service.hero_image.gatsbyImageData }
            alt={ service.hero_image.alt }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="100px 0 128px"
      >
        <SectionContainer>
          <SectionContent
            heading={ service.process_heading.text }
            description={ service.process_description.html }
            textAlign="center"
          />
          <Stack
            align="center"
            spacing={ 12 }
          >
            { service.processes.map(( node: any, _index: number ) => (
              <ProcessItem
                order={ node.process_item_order }
                icon={ node.process_item_icon }
                heading={ node.process_item_heading.text }
                description={ node.process_item_description.html }
              />
            ))}
          </Stack>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <SectionContainer>
          <GuaranteeFlexbox
            heading={ service.guarantee_heading.text }
            description={ service.guarantee_description.html }
            image={ service.guarantee_image.gatsbyImageData }
            alt={ service.guarantee_image.alt }
          />
          <GuaranteeWrap>
            { service.guarantees.map(( node: any, _index: number ) => (
              <GuaranteeCard
                key={ node.guarantees_item_heading.text }
                heading={ node.guarantees_item_heading.text }
                description={ node.guarantees_item_description.html }
              />
            ))}
          </GuaranteeWrap>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="linear-gradient(white 0%, white 57%, #F94E1911 57%, #F94E1911 100%)"
        padding="64px 0 57px"
      >
        <SectionContainer>
          <CommonPestsGrid
            heading={ service.pests_heading.text }
            description={ service.pests_description.html }
          >
            { pests.map(( node: any, _index: number ) => (
              <PestCard
                key={ node.node.uid }
                data={ node.node.data }
              />
            ))}
          </CommonPestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
        padding="155px 0"
      >
        <SectionContainer>
          <TermitesFlexbox
            direction="row"
            heading={ service.termites_heading.text }
            description={ service.termites_description.html }
            image={ service.termites_image.gatsbyImageData }
            alt={ service.termites_image.alt }
            imageBackground="white"
            imageMaxW={{
              base: '80%',
              sm: '320px',
              md: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ service.testimonials_heading.text }
          description={ service.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Service(
    $id: String!
  ) {
    allPrismicPest(
      filter: {
        data: {
          featured: {
            eq: true
          }
        }
      }
      sort: {
        fields: data___title___text
      }
    ) {
      edges {
        node {
          _previewable
          data {
            title {
              text
            }
            image {
              alt
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
          uid
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicService(
      id: {
        eq: $id
      }
    ) {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }
        hero_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        process_heading {
          text
        }
        process_description {
          html
        }
        processes {
          process_item_order
          process_item_icon
          process_item_heading {
            text
          }
          process_item_description {
            html
          }
        }

        guarantee_heading {
          text
        }
        guarantee_description {
          html
        }
        guarantee_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }
        guarantees {
          guarantees_item_heading {
            text
          }
          guarantees_item_description {
            html
          }
        }

        pests_heading {
          text
        }
        pests_description {
          html
        }

        termites_heading {
          text
        }
        termites_description {
          html
        }
        termites_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
      uid
    }
  }
`;


export default ServiceTemplate;
